export const generateTimeArray = () => {
  const timeArray = []
  for (let i = 0; i < 24; i++) {
    const hour = i < 10 ? `0${i}` : `${i}`
    const timeObject = {
      type: `${hour}:00:00`,
      name:
        i === 0
          ? "12:00 AM"
          : i < 12
          ? `${i}:00 AM`
          : i === 12
          ? "12:00 PM"
          : `${i - 12}:00 PM`,
    }

    timeArray.push(timeObject)
  }

  return timeArray
}
