export const deliveryTypes = () => {
  return [
    { text: "Local", value: "local" },
    { text: "Nacional", value: "national" },
  ]
}

export const deliveryOptions = () => {
  return [
    { value: "pickup", text: "Pick up" },
    { value: "delivery", text: "Delivery" },
    { value: "both", text: "Ambos" },
    { value: "not_applicable", text: "No aplica" },
  ]
}
